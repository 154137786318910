import React, { FC } from 'react';
import { useId } from 'react-id-generator';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Banner from 'components/Banner';
import { BannerMode } from 'components/Banner/models.d';
import PageDescription from 'components/PageDescription';
import SecondaryNavigation from 'components/SecondaryNavigation';
import { bodyAdapter } from 'utils/bodyAdapter';
import BodyRenderer from 'utils/bodyRenderer';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import { HelpAndSupportPageProps } from './models';

const FaqPage: FC<HelpAndSupportPageProps> = ({
  data: {
    helpAndSupportPage: { seo, langProps, banner, pageDescription, body },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
    pagePathname,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);
  const adaptedBody = bodyAdapter(body);

  const secondaryNavigationAnchors = adaptedBody
    .map((item) => item.value.secondaryNavigationAnchor)
    .filter((item) => item);

  return (
    <Layout {...{ seo, langProps, breadcrumbs, pagePathname }}>
      {banner ? <Banner {...banner[0]} mode={BannerMode.article} /> : null}
      {secondaryNavigationAnchors ? (
        <SecondaryNavigation anchors={secondaryNavigationAnchors} lang={langProps.lang} />
      ) : null}
      {pageDescription ? <PageDescription {...pageDescription} /> : null}

      {adaptedBody.map((component) => {
        const key = useId();

        return <BodyRenderer {...component} key={key} />;
      })}
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    helpAndSupportPage(link: { eq: $link }) {
      seo {
        ...SEOStructureFragment
      }
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      banner {
        ...BannerFragment
      }
      pageDescription {
        description
        backgroundColor
      }
      body {
        expertiseListing {
          ...ListingFragment
        }
        teaser {
          ...TeaserFragment
        }
        iframe {
          ...IframeFragment
        }
        contactUsText {
          secondaryNavigationAnchor
          title
          description
          sections {
            title
            description
          }
          backgroundColor
        }
        globalContactForm {
          formAddress
        }
        newsletterNodePicker {
          ...NewsletterBannerFragment
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default FaqPage;
